import React from "react";
import { BookNowCalendar } from "../components/calendar";

export const BookOnline = () => {
  return (
    <>
      <BookNowCalendar />
    </>
  );
};
